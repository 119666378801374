<template>
  <b-modal
    id="modal__TradeStudyCreate"
    title="Create a Trade Study"
    :ok-title="loading ? 'Loading...' : 'Create Trade Study'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    size="lg"
    lazy
    no-fade
    @ok.prevent="onSubmit"
    @show="onShow"
  >
    <b-form-group label="Trade Study title" label-for="trade-name">
      <b-form-input
        v-model="name"
        placeholder="Trade Study title"
      />
    </b-form-group>
    <b-form-group label="Description" label-for="trade-desc">
      <b-form-textarea
        v-model="description"
        placeholder="Give a brief description of the Trade Study..."
        rows="6"
      />
    </b-form-group>
    <b-form-group label="Scope" label-for="trade-scope">
      <b-form-textarea
        v-model="scope"
        placeholder="Give a brief overview of the scope..."
        rows="6"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'CreateTradeStudyModal',
  setup(props, context) {
    const loading = ref(false)
    const name = ref('')
    const description = ref('')
    const scope = ref('')

    const onSubmit = () => {
      loading.value = true
      const payload = {
        data: {
          name: name.value,
          description: description.value,
          scope: scope.value,
        },
      }

      store
        .dispatch('tradestudies/createTradeStudy', payload)
        .then(response => {
          context.root.$bvModal.hide('modal__TradeStudyCreate')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onShow = () => {
      // Clear the form
      name.value = ''
      description.value = ''
      scope.value = ''
    }

    return {
      name,
      description,
      scope,
      loading,
      onSubmit,
      onShow,
    }
  },
}
</script>
