<template>
  <b-modal
    id="duplicate-trade-study"
    title="Duplicate Trade Study"
    centered
    @ok="duplicateTS"
  >
    <div class="text-center mb-1">
      Create a duplicate for <span class="text-primary font-weight-bolder">{{ localTS.name }}</span>
    </div>

    <b-form-group label="Enter duplicate name:" label-for="ts-dupe-name">
      <b-form-input id="ts-dupe-name" v-model="dupeName" />
    </b-form-group>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" @click="ok()">
        Duplicate
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'DuplicateTS',
  setup() {
    const localTS = ref({})

    function setLocalFields() {
      if (store.state.tradestudies.selectedStudy.properties) {
        localTS.value = store.state.tradestudies.selectedStudy.properties
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy,
      () => setLocalFields(),
    )
    setLocalFields()

    const dupeName = ref('')

    function duplicateTS() {
      const duplicatePayload = {
        id: localTS.value.id,
        payload: {
          data: {
            new_ts_name: dupeName.value,
          },
        },
      }

      store.dispatch('tradestudies/duplicateTradeStudy', duplicatePayload)
    }

    return {
      localTS,
      dupeName,

      duplicateTS,
    }
  },
}
</script>

<style scoped>

</style>
