<template>
  <div id="TradeStudies">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Trade Studies</h2>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon" />
          Create Trade Study
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="fetchTradeStudies"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Trade Studies...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <trade-studies-table
        class="w-100"
      />
    </div>

    <div id="TS_Modals" class="d-none">
      <create-t-s />
      <update-t-s />
      <duplicate-t-s />
      <delete-t-s />
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import TradeStudiesTable from '@/views/TradeStudy/components/TradeStudiesTable.vue'
import DeleteTS from '@/views/TradeStudy/components/modals/DeleteTradeStudy.vue'
import CreateTS from '@/views/TradeStudy/components/modals/CreateTradeStudy.vue'
import UpdateTS from '@/views/TradeStudy/components/modals/UpdateTradeStudy.vue'
import DuplicateTS from '@/views/TradeStudy/components/modals/DuplicateTradeStudy.vue'

export default {
  name: 'TradeStudies',
  components: {
    TradeStudiesTable,
    DuplicateTS,
    UpdateTS,
    CreateTS,
    DeleteTS,
    AtomSpinner,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const loading = ref(false)
    const allTradeStudies = ref([])

    onUnmounted(() => {
      store.dispatch('tradestudies/clearTradeStudies')
    })

    const openCreateModal = () => {
      context.root.$bvModal.show('modal__TradeStudyCreate')
    }

    const fetchTradeStudies = () => {
      loading.value = true
      store.dispatch('tradestudies/getAllTradeStudies')
      loading.value = false
    }

    return {
      loading,
      allTradeStudies,
      fetchTradeStudies,

      openCreateModal,
    }
  },
}
</script>
