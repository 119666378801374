<template>
  <b-modal
    id="update-trade-study"
    title="Update Trade Study"
    centered
    @ok="updateTS"
  >
    <b-form-group label="Name" label-for="trade-name">
      <b-form-input v-model="name" />
    </b-form-group>
    <b-form-group label="Description" label-for="trade-desc">
      <b-form-textarea v-model="description" />
    </b-form-group>
    <b-form-group label="Scope" label-for="trade-scope">
      <b-form-textarea v-model="scope" />
    </b-form-group>
    <span v-if="localTS">
      <permissions v-if="auth.roles.includes('administrator')" ref="permissionsBox" hide-save-button="true" title="Permissions" node-type="Trade Study" :node-name="name" :node-id="localTS.id" />
    </span>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Dismiss
      </b-button>
      <b-button
        variant="success"
        @click="ok()"
      >
        Update
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import Permissions from '@/components/Permissions/Permissions.vue'

export default {
  name: 'UpdateTS',
  components: {
    Permissions,
  },
  setup() {
    const name = ref('')
    const description = ref('')
    const scope = ref('')
    const localTS = ref({})
    const auth = computed(() => store.state.auth)
    const permissionsBox = ref(null)

    function setLocalFields() {
      if (store.state.tradestudies.selectedStudy.properties) {
        localTS.value = store.state.tradestudies.selectedStudy.properties
        name.value = localTS.value.name
        description.value = localTS.value.description ?? ''
        scope.value = localTS.value.scope ?? ''
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy,
      () => setLocalFields(),
    )
    setLocalFields()

    function updateTS() {
      const updatePayload = {
        id: localTS.value.id,
        payload: {
          data: {
            trade_study_properties: {
              name: name.value,
              description: description.value,
              scope: scope.value,
            },
          },
        },
      }
      const result = permissionsBox.value?.savePermissions()
      store.dispatch('tradestudies/updateTradeStudy', updatePayload)
    }

    return {
      name,
      description,
      scope,
      localTS,
      auth,
      updateTS,
    }
  },
}
</script>

<style scoped>

</style>
