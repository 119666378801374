<template>
  <b-modal
    id="delete-trade-study"
    title="Delete Trade Study"
    centered
    @ok="deleteTS"
  >
    <p class="mt-25 mb-2">
      Are you sure you want to delete
      <span class="text-primary font-weight-bolder">{{ localTS.name }}</span>?
    </p>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Dismiss
      </b-button>
      <b-button
        variant="danger"
        @click="ok()"
      >
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'DeleteTS',
  setup(props, { emit }) {
    // Local Trade Study
    const localTS = ref({})
    function setLocalFields() {
      if (store.state.tradestudies.selectedStudy.properties) {
        localTS.value = store.state.tradestudies.selectedStudy.properties
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy,
      () => setLocalFields(),
    )
    setLocalFields()

    function deleteTS() {
      // console.log('Delete', localTS.value)
      store.dispatch('tradestudies/deleteTradeStudy', localTS.value.id)
        .then(() => emit('clearTemp'))
    }

    return {
      localTS,
      deleteTS,
    }
  },
}
</script>

<style scoped>

</style>
