<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      :items="studies"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      @row-contextmenu="item => tempRowObject = item"
      @contextmenu.native.prevent="$refs.menu.open"
    >
      <template #cell(created_by)="{ item }">
        <span class="font-small-3">
          {{ getUserUsername(item.created_by) }} : {{ item.created | diffForHumansMaxTwoWeeks }}
        </span>
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-inline-flex">
          <b-button
            v-b-tooltip.hover.top="'Open Trade Study'"
            variant="flat-primary"
            class="py-0 btn-icon"
            @click="openTradeStudy(item)"
          >
            <feather-icon icon="EditIcon" class="cursor-pointer" />
          </b-button>
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>

    <vue-context ref="menu" class="text-left">
      <li>
        <b-link @click="openTradeStudy(tempRowObject)">
          <feather-icon icon="FolderIcon" class="mr-50 mb-25 text-success" />
            Open
        </b-link>
      </li>
      <li>
        <b-link @click="updateTradeStudy(tempRowObject)">
          <feather-icon icon="EditIcon" class="mr-50 mb-25 text-success" />
          Update
        </b-link>
      </li>
      <li>
        <b-link @click="duplicateTradeStudy(tempRowObject)">
          <feather-icon icon="CopyIcon" class="mr-50 mb-25 text-info" />
          Clone
        </b-link>
      </li>
      <li>
        <b-link @click="deleteTradeStudy(tempRowObject)">
          <feather-icon icon="TrashIcon" class="mr-50 mb-25 text-danger" />
          Delete
        </b-link>
      </li>
    </vue-context>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BPagination,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'TradeStudiesTable',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BPagination,
    BTable,
    VueContext,
  },
  setup(props, context) {
    const studies = ref([])
    const totalRows = ref(1)
    function fetchTradeStudies() {
      store.dispatch('tradestudies/getAllTradeStudies')
        .then(() => {
          studies.value = store.state.tradestudies.allStudies
          totalRows.value = studies.value.length
        })
    }
    watch(
      () => store.state.tradestudies.allStudies,
      () => {
        studies.value = store.state.tradestudies.allStudies
        totalRows.value = studies.value.length
      },
    )
    fetchTradeStudies()
    // Table Stuff
    const fields = [
      {
        key: 'name',
        label: 'Name',
        sortable: true,
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'description',
        label: 'Description',
        sortable: true,
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'created_by',
        label: 'Created',
        sortable: true,
        thClass: 'text-center',
        tdClass: 'text-center',
        filterByFormatted: true,
        sortByFormatted: true,
        formatter: context.root.getUserUsername,
      },
      {
        key: 'actions',
        label: 'Actions',
        sortable: false,
        thClass: 'text-center',
        tdClass: 'text-center',
      },
    ]
    const filter = ref(null)
    const perPage = ref(20)
    const pageOptions = ref([10, 20, 50, 100])
    const currentPage = ref(1)
    const sortBy = ref('created')
    const sortDesc = ref(true)
    const sortDirection = ref('asc')
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    // Context menu
    const tempRowObject = ref({})

    return {
      studies,

      // Table Stuff
      sortOptions,
      sortBy,
      sortDesc,
      sortDirection,
      fields,
      filter,
      perPage,
      pageOptions,
      totalRows,
      currentPage,

      // Context menu
      tempRowObject,
    }
  },
  methods: {
    openTradeStudy(tradeStudyObj) {
      this.$router.push({
        name: 'trade_study_dedicated',
        params: {
          tradeId: tradeStudyObj.id,
        },
      })
    },
    updateTradeStudy(trade) {
      this.$store.dispatch('tradestudies/getSelectedTradeStudy', trade.id)
        .then(() => this.$bvModal.show('update-trade-study'))
    },
    deleteTradeStudy(trade) {
      this.$store.dispatch('tradestudies/getSelectedTradeStudy', trade.id)
        .then(() => this.$bvModal.show('delete-trade-study'))
    },
    duplicateTradeStudy(trade) {
      this.$store.dispatch('tradestudies/getSelectedTradeStudy', trade.id)
        .then(() => this.$bvModal.show('duplicate-trade-study'))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
</style>
